import React, {Component} from 'react'; 
import List from '../../components/list/list';
import Aux from '../../Hoc/Aux/Aux';
import Spinners from '../../Partials/Spinner/Spinner';
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'; 
import Response from '../../Partials/Response/Response';

class Users extends Component{

    state ={
       // users : [],
       // user:{},
      ///  showAddForm:false,
       // showProfile:false,
        //loading:true,
      //  loadingForChangedIndividualState: false,
       // id:"",
       // thedate: new Date(),
    }





    componentDidMount= () =>{
        //console.log(this.props);
        // getting customers
        this.props.falsifyRedirect();
        this.props.authfalsifyRedirect();
        this.props.usersInit(this.props.token);

    }

    // shouldComponentUpdate(nextProps, nextState){
    //     if (nextProps.user.id !== this.props.user.id) {
    //                console.log('state has changed nxtprops.'+nextProps.user.id+" nxt state->"+this.props.user);
    //                return true;
    //     }
    //     return false;
    // }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevState.user.id !== this.state.user.id) {
    //       console.log('pokemons state has changed.');
    //     }else{
    //         console.log('Nothing changed.');
    //     }
    //   }


    onshowProfileHandler = (val)=>{
        this.props.userProfile(val,this.props.token);
    }
   

 


    // onshowAddFormHandler = (val)=>{
    //     this.setState({showAddForm:true,user:val});
    //     console.log(val);
    // }

    // onCancelFormHandler = ()=>{
    //     //this.setState({showAddForm:false,showProfile:false});
    //     this.props.cancelProfileAndPopup();
    // }
    

    onGoToSummaryHandler = (id,name)=>{
      //  alert('summary '+id);
      //  console.log(this.props);
        this.props.history.push({
            pathname:'/account/summary',
            id:id,
            name:name
        });
    }


    onCheckBalHandler = (id,name)=>{
        //  alert('summary '+id);
        //  console.log(this.props);
          this.props.history.push({
              pathname:'/balance',
              id:id,
              name:name
          });
      }

    goToEditAccountHandler = (val) =>{
    //    console.log(val+" ooo");
        this.props.history.push({
            pathname:'/edit-account',
             user:val,
        });
    }



    // onDeleteHandler=(val,name)=>{
    
    //  this.props.deleteUser(val,name);
    // }




    render(){

        let customerList =   <List cancel={this.props.cancelProfileAndPopup}
                                  // showSaveForm={this.state.showAddForm} 
                                   onAdd={this.props.onshowAddFormHandler}
                                   onProfile={this.onshowProfileHandler}
                                   onDel={this.props.deleteUser}
                                   onSummary={this.onGoToSummaryHandler}
                                   {...this.props.allStates}
                                   myprops={this.props}
                                   checkBal={this.onCheckBalHandler}
                                   goToEditAccount={this.goToEditAccountHandler}
                                   />
        if(this.props.loadingStart){
            customerList = <Spinners/>;
        }
        return (
            <Aux>
                {this.props.error? <Response>{this.props.error}</Response>:null}
                {customerList}
           </Aux>
        )
    }

}


const mapStateToProps = state =>{
    return {
      //  users : state.users,
        allStates: state.usersReducer,
        user:state.usersReducer.user,
        error: state.usersReducer.error,
        loadingStart: state.usersReducer.loadingStart,
        loadingForChangedIndividualState: state.usersReducer.loadingForChangedIndividualState,
        token:state.authReducer.token,
    }
}



const mapDispatchToProps = dispatch =>{
    return {
        usersInit   : (token) => dispatch(actions.usersInit(token)),
        userProfile : (value,token) => dispatch(actions.userProfile(value,token)),
        cancelProfileAndPopup: ()=>dispatch(actions.cancelProfileAndPopupForm()),
        falsifyRedirect: ()=> dispatch(actions.falsifyRedirect()),
        authfalsifyRedirect: ()=> dispatch(actions.authfalsifyRedirect()),
        deleteUser:  (id,name,token)=> dispatch(actions.deleteUser(id,name,token)),
        onshowAddFormHandler: (rowData) => dispatch(actions.showAddForm(rowData))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Users);
