import React, { Component } from "react";
import Aux from "../../Hoc/Aux/Aux";


class Endsavings extends Component{

    

    render(){


        let btn = (
            <button className="btn btn-danger btn-block" 
            onClick={  ()=>{ 
                if(window.confirm('are you sure?')) 
                this.props.endSaving()}   
               }> End {this.props.name}'s Savings</button> 
        )

        if(this.props.endLoading){
            btn = '...loading'
        }

        let at = null;

        if(this.props.confirmCloseAlert){
         at = (<div className="alert alert-success mt-3">This savings has been closed</div>);
        }

        return(   <Aux>
                    {btn}
                    { at }
                 </Aux>
        )
    }

}

export default Endsavings;