import React, { Component } from 'react';
import Aux from '../../Hoc/Aux/Aux';
import styles from './NewAccount.module.css';
import formValidation from '../../Helpers/FormValidation/StandardForm';
import Spinner from '../../Partials/Spinner/Spinner';
import * as actions from '../../store/actions/index';
import {Redirect} from 'react-router-dom';
import {connect}  from 'react-redux';
import Response from '../../Partials/Response/Response';


class NewAccount extends Component {
  state = {
    myForm:{
      name:     '',
      phone:    '',
      address:  '',
      status:   '',

            formErrors:{
                name: '',
                phone: '',
                address:'',    
            }
    },
    // loading:false,
    // success: null,
    // error : null,
    display:false, // for hiding alert
    show: false ,

}



changeHandler = (event) =>{
       
  const {name, value} = event.target;
  //console.log(name + ' '+ value);
  let formErrs = this.state.myForm.formErrors;
  let formValue = this.state.myForm;
  // // validation
  const v = new formValidation('alertError',styles.Errors);
  const {errorState,valueState} = v.validation(name,value,formErrs,formValue);

  // //set state
  let  updateForm = {...this.state.myForm};
  updateForm.formErrors = errorState;
  updateForm = valueState;
  this.setState({myForm:updateForm});
}


componentDidMount(){
  // make sure redirect is false
  //this.props.falsifyRedirect();  did it in users - customers page
}


submitFormHandler=(event)=>{
  event.preventDefault();
  this.setState({loading:true});
   console.log(21);
  const {formErrors} = this.state.myForm;
  const {myForm} = this.state;
  const v = new formValidation('alertError',styles.Errors);
  if( v.isformValid(formErrors,this.state.myForm)){




    const info = {
      name:       myForm.name,
      phone:      myForm.phone,
      address:    myForm.address,
      status:     myForm.status,
   }


console.log(info);
this.props.submitNewAccountForm(info); 
//  post request





  }else{
      //error
      console.log("many errors");
  }




}

    render(){


      const formErz = this.state.myForm.formErrors;
      const fieldAlert = new formValidation('alertError',styles.Errors);

      const theForm = (
        <div className={["card", styles.cardsignin ,"my-5"].join(' ')}>
                   <div className="card-body">
                    <h5 className="card-title text-center">Register</h5>
                     <form className="form-signin" noValidate>


                     <div className={styles.formlabelgroup}>
                       <input type="text" className="form-control" name="name" placeholder="Name" onChange={this.changeHandler}  />
                       <div className={fieldAlert.alert(formErz.name).css}>{fieldAlert.alert(formErz.name).value}</div>
                    </div>


                     <div className={styles.formlabelgroup}>
                       <input type="text"  className="form-control" name="phone" placeholder="Phone"  onChange={this.changeHandler}  />
                       <div className={fieldAlert.alert(formErz.phone).css}>{fieldAlert.alert(formErz.phone).value}</div>
                    </div>



                    <div className={styles.formlabelgroup}>
                       <select type="text"  
                       className="form-control" 
                       name="status"
                       value={this.state.myForm.status}
                      placeholder="Select"  
                      onChange={this.changeHandler}>
                      <option value=""></option>
                      <option value="savings">savings</option>
                      <option value="loan">loan</option>
                      </select>
                      </div>

                    <div className={styles.formlabelgroup}>
                       <input type="text"  className="form-control" name="address" placeholder="Address" onChange={this.changeHandler}   />
                       <div className={fieldAlert.alert(formErz.address).css}>{fieldAlert.alert(formErz.address).value}</div>
                    </div>                   



     
                    <button className={[styles.btn, "btn-lg", "btn btn-primary", "btn-block", "text-uppercase" ].join(' ')}
                           disabled={!fieldAlert.isformValid(formErz,this.state.myForm)}
                           onClick={this.submitFormHandler}
                           >Register</button>
               </form>
            </div>
          </div>
      )


      let  pageRedirect= null;
      if(this.props.redirect){
          pageRedirect = <Redirect  to='/customers' />
      }



        return (
          
          
          
          <Aux>

                { pageRedirect}
                 {this.props.error ? <Response styles={['alert','alert-danger'].join(' ')}>{this.props.error.toString()}</Response> : null}
                  {this.props.loading ? <Spinner/> : theForm } 
                  
                
  
          </Aux>


        )
    }
}


const mapStateToProps = state =>{
  return{
       loading: state.accountReducer.loading,
       redirect: state.accountReducer.redirection,
       error:state.accountReducer.error
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    submitNewAccountForm : (values) => dispatch(actions.postNewAccount(values)),
  //  falsifyRedirect: ()=> dispatch(actions.falsifyRedirect())
  }
}


export default connect(mapStateToProps,mapDispatchToProps)(NewAccount);