
import React from 'react';
import Aux from '../../Hoc/Aux/Aux';
import Modal from '../../Partials/Modal/Modal';
import MaterialTable from 'material-table';
import PopupForm from '../popupform/popupform';
import Spinner from '../../Partials/Spinner/Spinner';
import {connect} from 'react-redux'

const list = (props) => {


const users = props.users;

 





// save data popup form
let addData = null;
if(props.showAddForm){
  addData = (
    <Modal show={props.showAddForm}>
    <div className=" mt-3">
    <PopupForm cancel={props.cancel} myprops={props.myprops} user={props.user} />
  </div>
  </Modal>
  )
}



//  data popup for inidividuals
let tableDetails = (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">Details</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{props.user.name}</td>
                        <td><button onClick={()=>props.goToEditAccount(props.user)} className="btn btn-primary">Edit</button></td>
                      </tr>
                      <tr>
                        <td>{props.user.phone}</td>
                        <td><button onClick={props.cancel} className="btn btn-danger">Cancel</button></td>
                      </tr>
                      <tr>
                        <td colSpan="2">{props.user.address}</td>
                      </tr>
                    </tbody>
                  </table>
);
let profile = null;
if(props.showProfile){
  addData = (
    <Modal show={props.showProfile}>
    <div className=" mt-3">
    { props.loadingForChangedIndividualState ?  <Spinner></Spinner>: tableDetails}
  </div>
  </Modal>
  )
}



const goToRegisterPage =() =>{
  //console.log(props.myprops);
  props.myprops.history.push('/new-account');
}






    return (
        
    <Aux>
    


    <div className={["card","my-5"].join(' ')}>
    <div className="card-body">
    <h5 className="card-title text-center text-primary">Customers </h5>


    <MaterialTable
          columns={[
                      { title: 'Names', field: 'name',
                          render: rowData =>{
                            let name = (<div className=" text-dark text-capitalize">{rowData.name} <span className="badge badge-danger">loan</span></div>);
                            if(rowData.status !== "loan"){
                              name = (<div className="text-dark text-capitalize">{rowData.name}</div>);
                            }
                            return name;
                          }, 
                      }
                  ]}

          data={users}
          title="Customers"
          options={{actionsColumnIndex: -1}}
          actions={[
            {
                icon: 'add',
                tooltip: 'Add User',
                isFreeAction: true,
                onClick: () => goToRegisterPage()
              },
              {
                icon: 'person',
                tooltip: 'Profile',
                onClick: (event,rowData) => props.onProfile(rowData.id)
              },
              {
                icon:'input',
                tooltip:'Deposit',
               // isFreeAction:true,
                onClick: (event,rowData) => props.onAdd(rowData)
              },
              {
                icon: 'list',
                tooltip: 'Account Summary',
                onClick: (event,rowData) => props.onSummary(rowData.id,rowData.name)
              },
              {
                icon: 'money',
                tooltip: 'Account Balance',
                onClick: (event,rowData) => props.checkBal(rowData.id,rowData.name)
              },

            {
              icon:'delete',
              tooltip: 'Delete User',
              onClick: (event,rowData) => props.onDel(rowData.id,rowData.name,props.token)
            },
          ]}
        />

</div>

</div>







{addData}
{profile}
    
    







</Aux>
     );
}
 

const mapStateToProps = state =>{
  return {
      token:state.authReducer.token,
  }
}

export default connect(mapStateToProps,null) (React.memo(list)); // React memo not to reload selected customer when checking profile