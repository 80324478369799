import * as actionTypes from '../actions/actionTypes'; 

const initialState ={
    loading:false,
    success: null,
    error : null,
    redirection: false,
    userid: '',

}

const accountReducer = (state = initialState, action)=>{
    switch(action.type){
        case actionTypes.CREATE_ACCOUNT_LOADING:
        return{
            ...state,
            redirection:false,
            loading:true
        }
        case actionTypes.CREATE_ACCOUNT_SUCCESS:
        return {
            ...state,
            loading:false,
            redirection:true
        }
        case actionTypes.CREATE_ACCOUNT_FAIL:
        return{
            ...state,
            loading:false,
            error: action.error
        }
        case actionTypes.FALSIFY_REDIRECT:
        return{
            ...state,
            redirection:false
        }
        case actionTypes.EDIT_ACCOUNT_LOADING:
        return {
            ...state,
            loading:true
        }
        case actionTypes.EDIT_ACCOUNT_SUCCESS:
        return{
            ...state,
            loading:false,
            redirection:true
        }
        case actionTypes.EDIT_ACCOUNT_FAIL:
        return {
            ...state,
            loading:false,
            error:action.error
        }
        case actionTypes.SET_USER_ID_FOR_EDIT_ACCOUNT:
        return{
            ...state,
            userid: action.id
        }
        default:
        return state

    }

}


export default accountReducer;