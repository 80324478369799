import React, { Component } from 'react';
import Aux from '../../Hoc/Aux/Aux';
import styles from './Login.module.css';
import formValidation from '../../Helpers/FormValidation/StandardForm'
import * as actions from '../../store/actions/index';
import {connect} from 'react-redux';
import Spinner from '../../Partials/Spinner/Spinner';
import Response from '../../Partials/Response/Response';
import {Redirect}from 'react-router-dom';

class Login extends Component {


  state ={
    myForm:{
      phone:    '',
      password:  '',
    },
    formErrors:{
      phone:    '',
      password: '',
    },
  }


  




  changeHandler = (event) =>{
       
    const {name, value} = event.target;
    const {myForm,formErrors} = this.state;
    const v = new formValidation();
    const {errorState,valueState} = v.validation(name,value,formErrors,myForm);
    //console.log(errorState,valueState);

    let  updateForm = {...this.state};
    updateForm.formErrors = errorState;
    updateForm.myForm = valueState;
    this.setState({myForm:updateForm.myForm,formErrors:updateForm.formErrors});

  }

  submitFormHandler=(event)=>{
    event.preventDefault();
    const info ={
      phone: this.state.myForm.phone,
      password: this.state.myForm.password
    }
    const v = new formValidation();
    const {myForm,formErrors} = this.state;
    if(v.isformValid(formErrors,myForm)){
      this.props.postAuth(info);
    }
     

  }




    render(){

     
      const {myForm,formErrors} = this.state;
      const fieldAlert = new formValidation('alertError',styles.Errors);

      let loginForm = ( 
                          <div className={["card", styles.cardsignin ,"my-5"].join(' ')}>
                            <div className="card-body">
                              <h5 className="card-title text-center">Sign In</h5>
                              <form className="form-signin">
                              <div className={styles.formlabelgroup}>
                                  <input type="text" name="phone"  className="form-control" 
                                  onChange={ (event)=>this.changeHandler(event)} placeholder="Phone"  value={myForm.phone} required />
                                <div className={fieldAlert.alert(formErrors.phone).css}>
                                {fieldAlert.alert(formErrors.phone).value}
                                </div>
                                </div>

                                <div className={styles.formlabelgroup}>
                                  <input type="password" name="password" className="form-control" 
                                  onChange={ (event)=>this.changeHandler(event)} placeholder="Password"   value={myForm.password} required/>
                                  <div className={fieldAlert.alert(formErrors.password).css}>{fieldAlert.alert(formErrors.password).value}</div>
                                </div>
                                  {console.log(!fieldAlert.isformValid(formErrors,myForm))}
                                <button 
                                                disabled={!fieldAlert.isformValid(formErrors,myForm)}
                                                onClick={this.submitFormHandler}
                                className={[styles.btn, "btn-lg", "btn btn-primary", "btn-block", "text-uppercase" ].join(' ')}type="submit">Sign in</button>
                              </form>
                            </div>
                            </div>
                           );

          if(this.props.loading){
            loginForm = (<Spinner></Spinner>);
          }                 


   
          
          let resp = null;
          if(this.props.error){
          resp = <Response styles={['alert','alert-danger'].join(' ')}>{this.props.error.toString()}</Response>
        
        }

          

        return (
            <Aux>
            <h5 className=" display-4 text-center text-warning">ProSavers</h5>
            
            {this.props.redirect?<Redirect to='/customers'/>:null}
            {resp}
            {loginForm}
  
          </Aux>
        )
    }
}



const mapStateToProps = state =>{
   return{
    loading: state.authReducer.loading,
    error:state.authReducer.error,
    redirect:state.authReducer.redirection,
   }
  }
  

  const mapDispatchToProps= dispatch =>{
    return {
      postAuth: (info)=> dispatch(actions.postAuth(info))
    }
  
  }
  

export default connect(mapStateToProps,mapDispatchToProps)(Login);
