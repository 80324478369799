import * as actionTypes from '../actions/actionTypes'; 

const initialState ={
     users : [],
     loadingStart : true,
     error : null,
     user:{},
     showAddForm:false,
     showProfile:false,
     loadingForChangedIndividualState: false,
    // id:"",
}


const usersReducer = (state = initialState,action) =>{
    switch (action.type){
        case actionTypes.USERS_LOADING_START:
        return {
            ...state,
            loadingStart :true,
            showAddForm:false,  // make sure popups & profile close
            showProfile:false,   // make sure popups & profile close
        }
        case actionTypes.USERS_SUCCESS:
        return{
            ...state,
            users: action.users,
            loadingStart :false,
        }
        case actionTypes.USERS_FAIL:
        return {
            ...state,
            error: action.error,
            loadingStart :false,
        }
        case actionTypes.USER_PROFILE_LOADING:
        return {
            ...state,
            loadingForChangedIndividualState:true,
            showProfile:true
        }
        case actionTypes.USER_PROFILE_SUCCESS:
        return{
            ...state,
            user:action.user,
            loadingForChangedIndividualState:false
        }
        case actionTypes.USER_PROFILE_FAIL:
        return{
            ...state,
            loadingForChangedIndividualState:false
        }
        case actionTypes.CANCEL_PROFILE_AND_POPUP_FORM:
        return{
            ...state,
            showAddForm:false,
            showProfile:false
        }
        case actionTypes.DELETE_USER_SUCCESS:
        return{
            ...state,
            users: state.users.filter( user=>user.id !== action.id)
        }
        case actionTypes.DELETE_USER_ERROR:
        return{
            ...state,
            error: action.error
        }
        case actionTypes.SHOW_ADD_FORM:
        return{
            ...state,
            showAddForm: true,
            user:action.user
        }
        default:
        return state

    }

}


export default usersReducer;