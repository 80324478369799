import React from 'react';
import MaterialTable from 'material-table';
import PopupForm from '../../popupform/popupform';
import Modal from '../../../Partials/Modal/Modal';
import PopupEditForm from'../../popupform/popupEditForm';
import Aux from '../../../Hoc/Aux/Aux';
import {connect} from 'react-redux';


const summary = (props) => {


  // useEffect(()=>{
  //   //console.log(11);
  //   props.falsifyRedirect();

  // });




  // save data popup form
let addData = null;
if(props.showAddForm){
  addData = (
    <Modal show={props.showAddForm}>
    <div className=" mt-3">
    <PopupForm   cancel={props.cancelPopup} myprops={props.myprops} user={props.user} />
  </div>
  </Modal>
  )
}




  // save data popup form
  // props are from allstate -> for transactions getting all rows
let editData = null;
if(props.showEditSavings){
  editData  = (
    <Modal show={props.showEditSavings}>
    <div className=" mt-3">
    <PopupEditForm 
    upstate={props.upstate} 
    cancel={props.cancel}
    cancelPopup={props.cancelPopup}
     name={props.name}
    transaction={props.transaction} 
    myprops={props.myprops} />
  </div>
  </Modal>
  )
}


    return ( 
      <Aux>
      <div className={["card","my-5"].join(' ')}>
      <div className="card-body">
      <h5 className="card-title text-center text-primary"> {props.name}'s summary account</h5>
        <MaterialTable
          columns={[

            { title: 'Date', field: 'mydate',type: 'date',
            render: rowData =>{
              let name = (<div className=" text-dark font-weight-bold">{rowData.mydate} </div>);
              if(rowData.status === "completed"){
                name = (<div className="text-secondary font-italic">{rowData.mydate}</div>);
              }else if(rowData.narration === "withdrawal"){
                name = (<div className="text-danger font-weight-bold">{rowData.mydate}</div>);
              }
              return name;
            }, 
        },
 
        { title: 'Amount (₦)', field: 'amount',
        render: rowData =>{
          const num =  new Intl.NumberFormat().format(rowData.amount) 
          let name = (<div className=" text-dark font-weight-bold">{"₦ "+num} </div>);
          if(rowData.status === "completed"){
            name = (<div className="text-secondary font-italic">{"₦ "+num}</div>);
          }else if(rowData.narration === "withdrawal"){
            name = (<div className="text-danger font-weight-bold">{"₦ "+num}</div>);
          }
          return name;
        }, 
       },

          ]}
          data={props.transactions}
          title="Summary"
          options={{
            actionsColumnIndex: -1
          }}
          actions={[
            {
              icon: 'add',
              tooltip: 'Insert',
              isFreeAction: true,
              onClick: () => props.insertData()
            },
            {
              icon: 'money',
              tooltip: 'Balance',
              isFreeAction: true,
              onClick: () => props.checkMyBalance()
            },
            {
              icon:'edit',
              tooltip:'edit',
              onClick: (event,rowData) => props.edit(rowData)
            },
            {
              icon:'delete',
              tooltip: 'Delete ',
              onClick: (event,rowData) => props.delete(rowData.id,rowData.amount,props.token)
            },
          ]}
        />
        </div>
        </div>
        {addData}
        {editData}

       </Aux>


       );
}


const mapStateToProps = state=>{
  return{
      token:state.authReducer.token,
      
  }
}

 
export default connect(mapStateToProps,null)(summary);