import React, { Component } from 'react';
import Summary from '../../components/list/summary/summary';
import Spinners from '../../Partials/Spinner/Spinner';
import Aux from '../../Hoc/Aux/Aux';
import * as actions from '../../store/actions/index';
import {connect} from 'react-redux';

class Summaries extends Component{

     state ={
          id:"",
          name:"",
    //      // transactions:[],
    //       transaction:{},
    //       loading:true,
    //       showEditSavings:false,
    }


    componentDidMount = ()=>{

        this.props.falsifyRedirect();
        const {id,name} = this.props.location;
        //console.log(id);
        if(id === null || id ==="" || id=== undefined){
            this.props.history.push({
                pathname:'/customers',
            });
        } 
        this.setState({id,name});

      this.props.summaryIdAndNameInit(id,name);  

      this.props.summaryInit(id,this.props.token);

    }



// updateTheState =(myid)=>{
//    // this.setState({loading:true});
//    // console.log(myid);
//     this.props.summaryUpdateTrans(myid);
    
// }


// editEachSavingsHandler =(data)=>{
//   console.log(data.id+' edit'+data.amount+ " "+data);
//   this.setState({showEditSavings:true,transaction:data});

// }

// onCancelFormHandler = ()=>{
//     this.setState({showEditSavings:false});
// }

// deleteEachSavingsHandler =(id,amount)=>{
//     //console.log(id+' delete'+amount);
    
// }


// cancelProfileAndPopup =(event)=>{
//     event.preventDefault();
//     console.log('hhhhh');
//     this.props.cancelProfileAndPopup();
// }

insertData =()=>{
    const {id,name} = this.state;
    const user = {id,name }
    this.props.onshowAddFormHandler(user);

}

checkMyBalance=()=>{
const {id,name} = this.state;
    this.props.history.push({
        pathname:'/balance',
        id:id,
        name:name
    });
   
    
}






    render(){


        let txn =   <Summary  
        
                             
                              user={this.props.user}
                              cancelPopup={this.props.cancelProfileAndPopup}
                              insertData ={this.insertData}
                              onAdd={this.props.onshowAddFormHandler}
                              checkMyBalance={this.checkMyBalance}
                              edit={this.props.summaryEditTransaction} 
                              delete={this.props.summaryDeleteOneTrans}
                              cancel={this.props.summaryTransCancel}
                              myprops={this.props}
                              upstate ={this.props.summaryUpdateTrans}
                              {...this.props.allStates}
                              {...this.props.allState}/>
        if(this.props.loading){
            txn = <Spinners></Spinners>;
        }


        return(
                 <Aux>
                     {txn}
                 </Aux>
                
        )
    }
}


const mapStateToProps = state=>{
    return{
        allState: state.summaryReducer,
        loading:state.summaryReducer.loading,
        error:state.summaryReducer.error,
        token:state.authReducer.token,
        user:state.usersReducer.user,
        allStates: state.usersReducer,
        
    }
}
const mapDispatchToProps = dispatch =>{
  return {
    falsifyRedirect: ()=> dispatch(actions.falsifyRedirectPopup()),
    summaryInit: (id,token)=>dispatch(actions.summaryInit(id,token)),
    summaryIdAndNameInit: (id,name)=> dispatch(actions.summaryIdAndNameInit(id,name)),
    summaryEditTransaction: (data)=> dispatch(actions.summaryEditTransaction(data)),
    summaryTransCancel: ()=>dispatch(actions.summaryTransCancel()),
    summaryUpdateTrans: (id,token)=>dispatch(actions.summaryUpdateTrans(id,token)),
    summaryDeleteOneTrans: (id,amount,token)=>dispatch(actions.summaryDeleteOneTrans(id,amount,token)),
    cancelProfileAndPopup: ()=>dispatch(actions.cancelProfileAndPopupForm()),
     onshowAddFormHandler: (rowData) => dispatch(actions.showAddForm(rowData)),
    
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Summaries);