import * as actionTypes from './actionTypes';
import AxiosInstance from '../../Helpers/Axios/axiosInstance';
import axios from 'axios';
import {showErrors} from '../actions/auth'

export const usersLoadingStart =()=>{
    return{
        type: actionTypes.USERS_LOADING_START
    }
}

export const usersFail =(err)=>{
    return{
        type: actionTypes.USERS_FAIL,
        error: err
    }
}

export const usersSuccess =(res)=>{
    return{
        type: actionTypes.USERS_SUCCESS,
        users: res
    }
}

export const usersInit =(token)=>{
    return dispatch =>{
       
        // you do this because of  manual refresh
        if(!token){
            token = localStorage.getItem('token');
        }
       

        dispatch(usersLoadingStart());
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        AxiosInstance.get(`/customers?token=${token}`,{cancelToken:source.token} ).then( resp =>{
           // const users = resp.data.users;
            dispatch(usersSuccess(resp.data.users))
            //this.setState({users,loading:false});
        }).catch(error => 
            {


                dispatch(showErrors(error,usersFail));

            } 
        );

    }

}



export const userProfileLoading =()=>{
    return{
        type: actionTypes.USER_PROFILE_LOADING
    }
}

export const userProfileSuccess =(user)=>{
    return{
        type: actionTypes.USER_PROFILE_SUCCESS,
        user:user
    }
}

export const userProfileFail =(err)=>{
    return{
        type: actionTypes.USER_PROFILE_FAIL,
        user:err
    }
}


export const userProfile =(val,token)=>{
    return dispatch =>{
        dispatch(userProfileLoading());
      //  this.setState({loadingForChangedIndividualState:true,showProfile:true});
        // getting inidividual customer
       const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
       const source = CancelToken.source();
       AxiosInstance.get(`/profile/${val}?token=${token}`,{cancelToken:source.token} ).then( resp =>{
           // console.log(token,resp.data.status);
           dispatch(userProfileSuccess(resp.data.data))
         //  this.setState({user,loadingForChangedIndividualState:false});
       }).catch(error => 
           {
               if (axios.isCancel(error)) {
                dispatch(userProfileFail(error));
                  // this.setState({loadingForChangedIndividualState:false});
                  // console.log('post Request canceled');
               }else{
                dispatch(userProfileFail(error));
                  // console.log(error);
                  // this.setState({loadingForChangedIndividualState:false});
               }
           } 
       );
    }
}

export const cancelProfileAndPopupForm =()=>{
    return{
        type: actionTypes.CANCEL_PROFILE_AND_POPUP_FORM
    }
}

export const deleteUserSuccess=(id)=>{
    return{
        type:actionTypes.DELETE_USER_SUCCESS,
        id:id
    }
}

export const deleteUserError=(err)=>{
    return{
        type:actionTypes.DELETE_USER_SUCCESS,
        error:err
    }
}


export const deleteUser =(val,name,token)=>{
    window.confirm(`Delete ${name} ?`);
    return dispatch =>{
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
     const source = CancelToken.source();
      AxiosInstance.get(`/customers/delete/${val}?token=${token}`,{cancelToken:source.token} ).then( resp =>{
       // console.log(token,resp.data.status);

        dispatch(deleteUserSuccess(val));
    }).catch(error => 
        {
            if (axios.isCancel(error)) {
              //  this.setState({loading:false});
              dispatch(deleteUserError(error))
            }else{
              //  console.log(error);
               // this.setState({loading:false});
               dispatch(deleteUserError(error))
            }
        } 
    );
    }
}

export const showAddForm =(rowData)=>{
    return {
      type: actionTypes.SHOW_ADD_FORM,
      user:rowData
    }
}