import React, { Component } from 'react';
import './App.css';
import Users from './containers/Users/Users';
import Layout from './Partials/layout/layout';
import {
  Switch,
  Route,
  withRouter,
  Redirect
} from "react-router-dom";
import Nav from './Partials/nav/nav';
import Aux from './Hoc/Aux/Aux';
import Login from './containers/Login/Login';
import NewAccount from './containers/NewAccount/NewAccount';
import EditAccount from './containers/NewAccount/EditAccount';
import Summaries from './containers/Summaries/Summaries';
import PopupEditForm from './components/popupform/popupEditForm';
import Balance from './containers/Balance/Balance';
import {connect} from 'react-redux';
import Logout from './containers/Login/Logout';
import Statistics from './containers/Statistics/Statistics';
import ChangePassword from './containers/ChangePassword/ChangePassword';
import * as actions from './store/actions/index';


class App extends Component {

componentDidMount(){
  this.props.checkState();
}


  render(){


    let Routes = (
        <Switch>
           <Route path="/" exact component={Login} />
           <Redirect to='/' />
        </Switch>
    );

    if(this.props.isAuth){
        Routes = (
          <Switch>
            <Route path="/customers" component={Users} />
            <Route path="/new-account" component={NewAccount} />
            <Route path="/edit-account" component={EditAccount} />
            <Route path="/edit-savings" component={PopupEditForm} />
            <Route path="/account/summary" component={Summaries} />
            <Route path="/balance" component={Balance} />
            <Route path="/statistics" component={Statistics} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/logout" component={Logout} />
            <Redirect to='/customers' />
          </Switch>
        );
    }




    return (
      <Aux>
        
        <Nav isAuth={this.props.isAuth}/>
        <Layout>
            {Routes}
        </Layout>
      </Aux>
      );

  }

  
}


const mapStateToProps = state =>{
  return {
    isAuth : state.authReducer.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    checkState : ()=> dispatch(actions.checkAuthenticationState())
  }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(App));
