import React from 'react';
import {NavLink} from 'react-router-dom';


const nav = (props) => {
    return ( 




<nav className="navbar navbar-expand-lg  navbar-dark bg-primary mb-3">
  <NavLink className="navbar-brand" to='/'>Prosavers</NavLink>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div className="navbar-nav">
     
     {props.isAuth ? 
     <NavLink to='/customers' 
      className="nav-item nav-link" >Customers
      </NavLink>:
      ""} 

     {props.isAuth ? 
     <NavLink to='/statistics' 
      className="nav-item nav-link" >Statistics
      </NavLink>:
      ""} 

        {props.isAuth ? 
        <NavLink to='/change-password' 
        className="nav-item nav-link" >Change Password
        </NavLink>:
         ""} 

       {props.isAuth ? 
        <NavLink to='/logout' 
        className="nav-item nav-link" >Logout
        </NavLink>:""} 


    </div>
  </div>
</nav>




     );
}
 
export default nav;