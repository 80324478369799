import * as actionTypes from '../actions/actionTypes'; 


const initialState = {
       id:"",
       name:"",
        transactions:[],
        error: null,
       transaction:{},
        loading:true,
        showEditSavings:false,
  
}


const summaryReducer =(state = initialState,action)=>{
    switch(action.type){
       case actionTypes.SUMMARY_LOADING:
       return{
           ...state,
           loading:true
       }
       case actionTypes.SUMMARY_SUCCESS:
       return{
           ...state,
           transactions:action.transactions,
           loading:false
       }
       case actionTypes.SUMMARY_FAIL:
       return {
           ...state,
           error:action.error,
           loading:false
       }
       case actionTypes.SUMMARY_ID_AND_NAME_INIT:
       return{
           ...state,
           name:action.name,
           id:action.id
       }
       case actionTypes.SUMMARY_EDIT_TRANSACTION:
       return{
           ...state,
           showEditSavings:true,
           transaction: action.transaction
       }
       case actionTypes.SUMMARY_TRANS_CANCEL:
       return {
           ...state,
           showEditSavings:false,
       }
       case actionTypes.SUMMARY_DELETE_TRANS:
       return{
           ...state,
           transactions: state.transactions.filter(transaction => transaction.id !==action.id  )
       }
       default:
       return state
    }

}

export default summaryReducer;