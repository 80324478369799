import React from 'react';

const footer = () => {
    return ( 
        <footer className="pt-4 my-md-5 pt-md-5 border-top">
        <div className="row">
          <div className="col-12 col-md">
            <img className="mb-2" src="" alt="" width="24" height="24" />
            <small className="d-block mb-3 text-default">&copy; 2020</small>
          </div>
        </div>
    </footer>
     );
}
 
export default footer;