import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
import StatisticForm from '../../components/statisticForm/StatisticForm';



class Statistics extends Component {


    state ={
        myForm:{
          dateOne:    new Date(),
          dateTwo:    new Date(),
        },
    }


    changeDateOne = date => {
        let  updateForm = {...this.state.myForm};
         updateForm.dateOne = date ;
         this.setState({myForm:updateForm});
      }

      changeDateTwo = date => {
        let  updateForm = {...this.state.myForm};
         updateForm.dateTwo = date ;
         this.setState({myForm:updateForm});
      }

      getMyDate = (currentDate) =>{
        const date = currentDate.getDate();
        const month = currentDate.getMonth(); //Be careful! January is 0 not 1
        const year = currentDate.getFullYear();
      return  year + "-" +('0' + (month + 1)).slice(-2) + "-" +('0' + date).slice(-2);
      }
      



      submitFormHandler =(event) =>{
          event.preventDefault();
          const {myForm} = this.state;
          const info = {
            from:this.getMyDate(myForm.dateOne),
            to: this.getMyDate(myForm.dateTwo),
         }
         //console.log(info);
        this.props.statistics(info,this.props.token);
      }


    render(){

        return (
            <div className={["card","my-5"].join(' ')}>
            <div className="card-body">
            <h5 className="card-title text-center text-primary"> Check Statistics</h5>
                <StatisticForm 
                    {...this.state} 
                    changeDateOne={this.changeDateOne}
                    changeDateTwo={this.changeDateTwo}
                    submit={this.submitFormHandler}
                    loading={this.props.loading}
                    success={this.props.success}
                    error={this.props.error}
                />


       

            </div>
            </div>
        )
    }
}


const mapStateToProps = state =>{
    return{
         loading: state.statisticsReducer.loading,
         success: state.statisticsReducer.success,
         error:state.statisticsReducer.error,
         token:state.authReducer.token,
    }
  }
  
  const mapDispatchToProps = dispatch =>{
    return {
      statistics: (info,token) => dispatch(actions.statistics(info,token)),
    }
  }
  
  
  export default connect(mapStateToProps,mapDispatchToProps)(Statistics);