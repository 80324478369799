import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading:false,
    error:null,
    success:null,

}

const statisticsReducer =(state = initialState,action)=>{
    switch(action.type){
        case actionTypes.STATISTICS_LOADING:
        return{
            ...state,
            loading:true,    
            error:null,
            success:null,
        }
        case actionTypes.STATISTICS_SUCCESS:
        return{
            ...state,
            loading:false,
            success:action.success,
        }
        case actionTypes.STATISTICS_FAIL:
        return{
            ...state,
            loading:false,
            error:action.error
        }
        default:
        return state
    }

}

export default statisticsReducer;