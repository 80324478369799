import React,{Component} from 'react';
import Aux from '../../Hoc/Aux/Aux';

import Footer from '../../Partials/footer/footer';


class Layout extends Component  {


  render(){

    

      return(
         <Aux>
            
              <div className="container">
              <div className="col-12 col-md">
              {this.props.children}
              </div>
              <Footer />
            </div>
            
        </Aux>
      ) 
  
  }
}




export default Layout;