import React ,{Component} from  'react';
import styles from './popupform.module.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formValidation from '../../Helpers/FormValidation/StandardForm';
import axiosInstance from '../../Helpers/Axios/axiosInstance';
import axios from 'axios';
import Aux from '../../Hoc/Aux/Aux';
import Spinner from '../../Partials/Spinner/Spinner';
import {connect} from 'react-redux';



class PopupEditForm extends Component {

  state ={
    id:'',
    myForm:{
      date:     '',
      amount:    '',
      narration: '',
    },
    formErrors:{
     amount: '',
    },
    loading:false,
  }



  componentDidMount(){

    this.setState({
      ...this.state,
      id:this.props.transaction.id,
       myForm:{
         ...this.state.myForm,
             date:this.getMyFullDate(this.props.transaction.mydate),
             amount:this.props.transaction.amount,
             narration:this.props.transaction.narration,
       }
    });
    
    ///console.log(this.props.transaction.user_id,this.props.name,this.state)
    console.log(this.state);
}



getMyDate = (currentDate) =>{
  const date = currentDate.getDate();
  const month = currentDate.getMonth(); //Be careful! January is 0 not 1
  const year = currentDate.getFullYear();
return  year + "-" +(month + 1) + "-" + date;
}



getMyFullDate = (cDate) =>{
  let parts = cDate.split('-');
  console.log(parts);
  // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
  // January - 0, February - 1, etc.
  const mydate = new Date(parts[0], parts[1] - 1, parts[2]); 
  return mydate;
}





  changeHandler = (event) =>{
       
    const {name, value} = event.target;
    console.log(name + ' '+ value);
    const {myForm,formErrors} = this.state;
  //   // // validation
    const v = new formValidation('alertError',styles.Errors);
    const {errorState,valueState} = v.validation(name,value,formErrors,myForm);

    //console.log(errorState,valueState);
  //   // //set state
    let  updateForm = {...this.state};
    updateForm.formErrors = errorState;
    updateForm.myForm = valueState;
    this.setState({myForm:updateForm.myForm,formErrors:updateForm.formErrors});
  }



  changeDate = date => {
    let  updateForm = {...this.state.myForm};
     updateForm.date = date ;
     this.setState({myForm:updateForm});
   // console.log(this.state.myForm.date);
   // this.setState({...this.state,.myForm, date});
  }

  submitFormHandler=(event)=>{
    event.preventDefault();
    this.setState({loading:true});
  const {id,myForm,formErrors} = this.state;
  const v = new formValidation('alertError',styles.Errors);
  if( v.isformValid(formErrors,myForm)){

    const info = {
      mydate:       this.getMyDate(myForm.date),
      amount:       myForm.amount,
      user_id:      this.props.transaction.user_id,
      narration:    myForm.narration,
      status:       myForm.status
   }


// //console.log(info.mydate +' noqqq');

const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
const source = CancelToken.source();
axiosInstance.put(`update/save/${id}`,info,{
  cancelToken: source.token
}).then(resp =>{
  //this.setState({loading:false});
 //console.log(resp);
//   this.props.myprops.history.push({
//     pathname:'/account/summary',
//     id:this.props.transaction.user_id,
//     name:this.props.name
// });
   this.props.upstate(this.props.transaction.user_id,this.props.token) // update the state from summaries.js
   this.props.cancelPopup(); // cancel popup
  // this.props.cancel();

}).catch(error => 
  {
      if (axios.isCancel(error)) {
          this.setState({loading:false});
          console.log('post Request canceled');
      }else{
          console.log(error)
          this.setState({loading:false});
      }
  } 
);
  }else{
      //error
      console.log("many errors");
  }
  }

  render(){

    

    const {formErrors,myForm }= this.state;
    const fieldAlert = new formValidation('alertError',styles.Errors);
    //  console.log(formErrors,myForm);

      let form = (
                  <form className="form-inline">
                  <div className={styles.formlabelgroup}>
                  <DatePicker
                          selected={myForm.date}
                          className="form-control mr-3" 
                          name="date"
                          dateFormat="yyyy-MM-dd"
                          onChange={date => this.changeDate(date)}
                        />
                  </div>   
                <div className={styles.formlabelgroup}>
                  <input type="text" value={myForm.amount} className="form-control" onChange={this.changeHandler} name="amount" placeholder="Amount"   />
                  <div className={fieldAlert.alert(formErrors.amount).css}>{fieldAlert.alert(formErrors.amount).value}</div>
                </div>  

                <div className={styles.formlabelgroup}>
                <select name="narration" className="form-control mr-3" onChange={this.changeHandler}>
                  <option select value={myForm.narration}>{myForm.narration}</option>
                  <option value="deposit">Deposit</option>
                  <option value="withdrawal">Withdrawal</option>
                </select>
                </div>
     


                <div className={styles.formlabelgroup}>
                <button onClick={this.props.cancel} className={[styles.btn, "btn btn-danger", "mr-3" ,"text-uppercase" ].join(' ')} >Cancel</button>
                <button type="submit" className={[styles.btn, "btn btn-primary", "text-uppercase" ].join(' ')} 
                disabled={!fieldAlert.isformValid(formErrors,myForm)}
                onClick={this.submitFormHandler}
                >Edit</button>
                </div>



                </form> 
      );
      if(this.state.loading){
        form = (<Spinner></Spinner>);
      }


    return (
            <Aux>
              {form}
            </Aux>
    )

  }



}


const mapStateToProps = state=>{
  return{
      token:state.authReducer.token,
      
  }
}

 
export default  connect(mapStateToProps,null)(PopupEditForm);