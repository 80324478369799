import React, { Component } from 'react'; 
import Aux from '../../Hoc/Aux/Aux';




class Response extends Component {


    render(){

        return ( 
            <Aux> 
                 
                 <div style={{marginTop:"10px"}} className={this.props.styles} role="alert">  {this.props.children} </div>
                
            </Aux>
       );
          }

    }
  

 
export default Response;