import * as actionTypes from './actionTypes';
import  axiosInstance from '../../Helpers/Axios/axiosInstance';
import  axios from 'axios';

export const createAccountLoading = ()=>{
    return {
        type : actionTypes.CREATE_ACCOUNT_LOADING
    }
}

export const createAccountSuccess = ()=>{
    return {
        type : actionTypes.CREATE_ACCOUNT_SUCCESS
    }
}


export const setUserIdForEditAccount = (id)=>{
    return {
        type : actionTypes.SET_USER_ID_FOR_EDIT_ACCOUNT,
        id:id

    }
}

export const createAccountFail = (error)=>{
    return {
        type : actionTypes.CREATE_ACCOUNT_FAIL,
        error:error
    }
}

export const falsifyRedirect = ()=>{
    return {
        type : actionTypes.FALSIFY_REDIRECT,
    }
}


export const postNewAccount =(info)=>{
    return dispatch =>{

           dispatch(createAccountLoading());
            const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
            const source = CancelToken.source();
            axiosInstance.post('/register',info,{
            cancelToken: source.token
            }).then(resp =>{
            //this.setState({loading:false});
            // console.log(resp);
            // this.props.history.push({
            //     pathname:'/customers'
            // });
            dispatch(createAccountSuccess())
            }).catch(error => 
            {
                if (axios.isCancel(error)) {
                    dispatch(createAccountFail());
                    // this.setState({loading:false});
                    // console.log('post Request canceled');
                }else{
                  //  console.log(error);
                    dispatch(createAccountFail(error));
                    // this.setState({loading:false});
                }
            } 
            );
                }
 }

 export const editAccountLoading =()=>{
     return {
         type: actionTypes.EDIT_ACCOUNT_LOADING
     }
 }

 export const editAccountFail =(err)=>{
    return {
        type: actionTypes.EDIT_ACCOUNT_FAIL,
        error: err
    }
}

export const editAccountSuccess =()=>{
    return {
        type: actionTypes.EDIT_ACCOUNT_SUCCESS
    }
}

export const postEditAccount =(id,info ) =>{
    return dispatch => {

        dispatch(editAccountLoading());

        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        axiosInstance.put(`profile/update/${id}`,info,{
        cancelToken: source.token
        }).then(resp =>{
        //this.setState({loading:false});
        //console.log(resp);
        dispatch(editAccountSuccess());
        // this.props.history.push({
        //     pathname:'/customers'
        // });
        }).catch(error => 
        {
            if (axios.isCancel(error)) {
                dispatch(editAccountFail(error))
            }else{
               dispatch(editAccountFail(error))
            }
        } 
        );
                
            }


}