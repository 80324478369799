import React from 'react';
import styles from '../balanceform/balanceform.module.css'
import DatePicker from "react-datepicker";
import Aux from '../../Hoc/Aux/Aux';
import Spinner from '../../Partials/Spinner/Spinner';
import Response from '../../Partials/Response/Response';


const StatisicForm = (props) => {


                  let mainForm = (<form className="form-signin" noValidate>
                  <div className={styles.formlabelgroup}>
                  <div><strong>FROM:</strong></div>
                  <DatePicker
                    selected={props.myForm.dateOne}
                    className="form-control mr-3" 
                    name="dateOne"
                    dateFormat="yyyy-MM-dd"
                    onChange={date => props.changeDateOne(date)}
                  />

                  <div><strong>TO:</strong></div>
                  <DatePicker
                    selected={props.myForm.dateTwo}
                    className="form-control mr-3" 
                    name="dateTwo"
                    dateFormat="yyyy-MM-dd"
                    onChange={date2 => props.changeDateTwo(date2)}
                  />
                  </div>   

                  <button type="submit" className={[styles.btn, "btn btn-secondary ", "text-uppercase" ].join(' ')} 
                  onClick={props.submit}>Submit</button>
                  </form> 
                );

                if(props.loading){
                  mainForm = <Spinner />
                }


                let inflow = 0;
                let outflow = 0;
                let bal= 0;
                if(props.success){
                  inflow =  props.success.deposit;
                  outflow =  props.success.withdrawal;
                  bal =  props.success.bal;
                }
      


    return (  
    
              <Aux>
               
                 {props.error ? <Response styles={['alert','alert-danger'].join(' ')} >{props.error.toString()}</Response>: null }
                    {mainForm}

                    
                     
            <div className="mt-3">
             <div className="alert alert-success">
               <strong> Total Inflow:</strong> {"₦ "+ inflow}
               </div>
               <div className="alert alert-danger">
               <strong> Total Outflow:</strong> {"₦ "+ outflow}
               </div>
               <div className="alert alert-primary">
               <strong> Balance:</strong> {"₦ "+ bal}
               </div>
               </div>
                     
              </Aux>

  
  );
}
 
export default StatisicForm;