import * as actionTypes from './actionTypes';
import  axiosInstance from '../../Helpers/Axios/axiosInstance';
import  axios from 'axios';


export const showErrors = (error,myFunction)=>{

    return dispatch=> {
        if (axios.isCancel(error)) {
            dispatch(myFunction(error));
        }else if(error.response){
      //  console.log(error,'error resp')
        dispatch(myFunction(error.response.data.error));
        }else{
      //  console.log(error,'error')
        dispatch(myFunction(error));
        }
    }
}




export const authLoading =()=>{
    return {
       type: actionTypes.AUTH_LOADING
    }
}


export const authSuccess =(token,userId)=>{
    return {
       type: actionTypes.AUTH_SUCCESS,
       token:token,
       userId:userId
    }
}


export const authFail =(err)=>{
    return {
       type: actionTypes.AUTH_FAIL,
       error:err
    }
}

export const autoLogout=()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('expiryDate');
    return {
        type:actionTypes.AUTO_LOGOUT
    }
}

export const checkExpiry=(ex)=>{
    return dispatch=>{
        setTimeout(()=>{
            dispatch(autoLogout());
        },ex * 1000)
    }

}

export const authfalsifyRedirect = ()=>{
    return {
        type : actionTypes.AUTH_FALSIFY_REDIRECT,
    }
}



export const postAuth =(info)=>{
    return dispatch=> {
        dispatch(authLoading());
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        axiosInstance.post('/login',info,{
            cancelToken: source.token
        }).then(resp =>{
            const {token,expires,userId} = resp.data
           // console.log(expires);
            const expiryDate = new Date (new Date().getTime() + (expires * 1000));
            localStorage.setItem('token',token);
            localStorage.setItem('userId',userId);
            localStorage.setItem('expiryDate',expiryDate);
            dispatch(authSuccess(token,userId));
            dispatch(checkExpiry(expires)); // no need for dispatching this
        }).catch(error =>{
            dispatch(showErrors(error,authFail));
        });
    }
}



export const checkAuthenticationState =()=>{
    return dispatch =>{
        const token = localStorage.getItem('token');
        if(!token){
         //   console.log('no token');
            dispatch(autoLogout());
        }else{
            const  expiryDate = new Date(localStorage.getItem('expiryDate'));
            if(expiryDate <= new Date()){
             //   console.log('theres token',expiryDate ,new Date() );
                dispatch(autoLogout());
            // success
            }else{
             //   console.log('good');
                const  userId = localStorage.getItem('userId');
                dispatch(authSuccess(token,userId));
                const expires = ((expiryDate.getTime() - new Date().getTime())/1000);
                dispatch(checkExpiry(expires));
            }

        }

    }
}







export const passLoading =()=>{
    return {
       type: actionTypes.PASS_LOADING
    }
}


export const passSuccess =(val)=>{
    return {
       type: actionTypes.PASS_SUCCESS,
       success:val
    }
}


export const passFail =(err)=>{
    return {
       type: actionTypes.PASS_FAIL,
       error:err
    }
}


export const changePassword=(id,info,token)=>{
    return dispatch=>{
        dispatch(passLoading());
        const CancelToken = axios.CancelToken;  
        const source = CancelToken.source();
        axiosInstance.post(`/change-password/${id}?token=${token}`,info,{
            cancelToken: source.token
        }).then(resp =>{
            const {success} = resp.data
            console.log(success);
            dispatch(passSuccess(success));
        }).catch(error =>{
            dispatch(showErrors(error,passFail));
        });
    }
}