import React ,{Component} from  'react';
import styles from './popupform.module.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import formValidation from '../../Helpers/FormValidation/StandardForm';
import Aux from '../../Hoc/Aux/Aux';
import Spinner from '../../Partials/Spinner/Spinner';
import * as  actions from '../../store/actions/index';
import Response from '../../Partials/Response/Response';
import {connect} from 'react-redux';
import {Redirect } from 'react-router-dom';

class Popupform extends Component {

  state ={
    myForm:{
      date:     new Date(),
      amount:    '',
      narration:'deposit',
    },
    formErrors:{
     amount: '',
    },
   // loading:false,
  }




getMyDate = (currentDate) =>{
  const date = currentDate.getDate();
  const month = currentDate.getMonth(); //Be careful! January is 0 not 1
  const year = currentDate.getFullYear();
return  year + "-" +(month + 1) + "-" + date;
}




  changeHandler = (event) =>{
       
    const {name, value} = event.target;
    //console.log(name + ' '+ value);
    const {myForm,formErrors} = this.state;
  //   // // validation
    const v = new formValidation('alertError',styles.Errors);
    const {errorState,valueState} = v.validation(name,value,formErrors,myForm);
  

    //console.log(errorState,valueState);
  //   // //set state
    let  updateForm = {...this.state};
    updateForm.formErrors = errorState;
    updateForm.myForm = valueState;
    this.setState({myForm:updateForm.myForm,formErrors:updateForm.formErrors});
  }



  changeDate = date => {
    let  updateForm = {...this.state.myForm};
     updateForm.date = date ;
     this.setState({myForm:updateForm});
   // console.log(date.getFullYear());
   // this.setState({...this.state,.myForm, date});
  }

  submitFormHandler=(event)=>{
    event.preventDefault();
    this.setState({loading:true});
  const {myForm,formErrors} = this.state;
  const v = new formValidation('alertError',styles.Errors);
  if( v.isformValid(formErrors,myForm)){

    const info = {
      mydate:       this.getMyDate(myForm.date),
      amount:       myForm.amount,
      user_id:      this.props.user.id,
      narration:     myForm.narration,
   }

   //console.log(info.mydate +' '+info.amount+' '+this.props.user.name+" "+this.props.user.id);
 
   this.props.createPopup(info,this.props.token);
//console.log(info.mydate +' noqqq');


  }else{
      //error
      console.log("many errors");
  }
  }

  render(){



    let redirectToSavings = null;
    if(this.props.redirect){
      
      redirectToSavings = <Redirect to={{
        pathname:'/account/summary',
        id:this.props.user.id,
        name:this.props.user.name
      }} />
      
     }




    const {formErrors,myForm }= this.state;
    const fieldAlert = new formValidation('alertError',styles.Errors);
      //console.log(formErrors,myForm);

      let form = (
                  <form className="form-inline">
                  <div className={styles.formlabelgroup}>
                  <DatePicker
                          selected={myForm.date}
                          className="form-control mr-3" 
                          name="date"
                          dateFormat="yyyy-MM-dd"
                          onChange={date => this.changeDate(date)}
                        />
                  </div>   
                <div className={styles.formlabelgroup}>
                  <input type="text"  className="form-control" onChange={this.changeHandler} name="amount" placeholder="Amount"   />
                  <div className={fieldAlert.alert(formErrors.amount).css}>{fieldAlert.alert(formErrors.amount).value}</div>
                </div> 
                <br/>

                <div className={styles.formlabelgroup}>
                <select name="narration" className="form-control mr-3" onChange={this.changeHandler}>
                  <option value="deposit">Deposit</option>
                  <option value="withdrawal">Withdrawal</option>
                </select>
                </div>

                <div className={styles.formlabelgroup}>
                <button onClick={this.props.cancel} className={[styles.btn, "btn btn-danger", "mr-3" ,"text-uppercase" ].join(' ')} >Cancel</button>
                <button type="submit" className={[styles.btn, "btn btn-primary", "text-uppercase" ].join(' ')} 
                disabled={!fieldAlert.isformValid(formErrors,myForm)}
                onClick={this.submitFormHandler}
                >Submit</button>
                </div>

              
 







                </form> 
      );
      if(this.props.loading){
        form = (<Spinner></Spinner>);
      }


    return (
            <Aux>
              {redirectToSavings}
              {this.props.error ? <Response styles={['alert','alert-danger'].join(' ')} >{this.props.error.toString()}</Response>: null }
             {form}
            </Aux>
    )

  }



}


const mapStateToProps = state =>{
return{
  loading: state.popupReducer.loading,
  error:state.popupReducer.error,
  redirect:state.popupReducer.redirection,
  token:state.authReducer.token,
}
}


const mapDispatchToProps= dispatch =>{
  return {
    createPopup : (info,token)=> dispatch(actions.createPopup(info,token))
  }

}


 
export default connect(mapStateToProps,mapDispatchToProps)(Popupform);