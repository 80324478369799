import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading:false,
    error:null,
    redirection:false

}

const popupReducer =(state = initialState,action)=>{
    switch(action.type){
        case actionTypes.CREATE_POPUP_FORM_LOADING:
        return{
            ...state,
            loading:true
        }
        case actionTypes.CREATE_POPUP_FORM_SUCCESS:
        return{
            ...state,
            loading:false,
            redirection:true
        }
        case actionTypes.CREATE_POPUP_FORM_ERROR:
        return{
            ...state,
            loading:false,
            error:action.error
        }
        case actionTypes.FALSIFY_REDIRECT:
        return{
            ...state,
            redirection:false
        }
        default:
        return state
    }

}

export default popupReducer