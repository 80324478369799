import React, { Component } from 'react';
import BalanaceForm from '../../components/balanceform/balanceform';
import Endsavings from './Endsavings';
import {connect} from 'react-redux';
import * as actions from '../../store/actions/index';
// import Aux from '../../Hoc/Aux/Aux';
// import Spinner from '../../Partials/Spinner/Spinner';



class Balance extends Component {


    state ={
        id:'',
        name:'',
        myForm:{
          dateOne:    new Date(),
          dateTwo:    new Date(),
        },
       // balance:null,
        //loading:true,
        // searchBalance:null,
        // searchLoading:false,
        //endLoading:false,
        //confirmCloseAlert:false
      }





    componentDidMount = ()=>{
        const {id,name} = this.props.location;
        console.log(id);
        if(id === null || id ==="" || id=== undefined){
            this.props.history.push({
                pathname:'/customers',
            });
        } 
        this.setState({id,name});
    // ============get full balance

      this.props.balanceInit(id,this.props.token);

    }








    changeDateOne = date => {
        let  updateForm = {...this.state.myForm};
         updateForm.dateOne = date ;
         this.setState({myForm:updateForm});
      }

      changeDateTwo = date => {
        let  updateForm = {...this.state.myForm};
         updateForm.dateTwo = date ;
         this.setState({myForm:updateForm});
      }

      getMyDate = (currentDate) =>{
        const date = currentDate.getDate();
        const month = currentDate.getMonth(); //Be careful! January is 0 not 1
        const year = currentDate.getFullYear();
      return  year + "-" +('0' + (month + 1)).slice(-2) + "-" +('0' + date).slice(-2);
      }
      


      onGoSummary =(id,name)=>{
        //this.props.history.goBack();
        this.props.history.push({
          pathname:'/account/summary',
          id:id,
          name:name
      });
      }


      submitFormHandler =(event) =>{
          event.preventDefault();
          this.setState({searchLoading:true});
          const {id,myForm} = this.state;
          const info = {
            from:this.getMyDate(myForm.dateOne),
            to: this.getMyDate(myForm.dateTwo),
         }
         console.log(id,info.from);
         this.props.postSearchForm(id,info);


      }



      endSaving = ()=>{
        
        this.props.endSavings(this.state.id,this.props.token)

      }








    render(){

        return (
            <div className={["card","my-5"].join(' ')}>
            <div className="card-body">
            <h5 className="card-title text-center text-primary"> Check {this.state.name}'s Balance</h5>
                <BalanaceForm  {...this.state} 
                               balance={this.props.balance}
                               loading={this.props.loading}
                               searchLoading={this.props.searchLoading}
                               searchBalance={this.props.searchBalance}
                               endLoading={this.props.endLoading}
                               confirmCloseAlert={this.props.confirmCloseAlert}
                               changeDateOne={this.changeDateOne}
                               changeDateTwo={this.changeDateTwo}
                               error={this.props.error}
                               summary={this.onGoSummary}
                                submit={this.submitFormHandler} />


             <hr/>

             <Endsavings endSaving={this.endSaving} confirmCloseAlert={this.props.confirmCloseAlert} endLoading={this.props.endLoading}  />


            </div>
         
              

            </div>
        )
    }
}

const mapStateToProps= state=>{
  return{
    loading:  state.balanceReducer.loading,
    error:    state.balanceReducer.error,
    balance:  state.balanceReducer.balance,
    searchBalance: state.balanceReducer.searchBalance,
    searchLoading: state.balanceReducer.searchLoading,
    endLoading:state.balanceReducer.endLoading,
    confirmCloseAlert:state.balanceReducer.confirmCloseAlert,
    token: state.authReducer.token,

  }
}


const mapDispatchToProps = dispatch =>{
return{
  balanceInit: (id,token)=> dispatch(actions.balanceInit(id,token)),
  postSearchForm: (id,val)=>dispatch(actions.postSearchForm(id,val)),
  endSavings: (id,token)=>dispatch(actions.endSavings(id,token))
}
}

export default connect(mapStateToProps,mapDispatchToProps)(Balance);