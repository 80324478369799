import * as actionTypes from './actionTypes';
import  axiosInstance from '../../Helpers/Axios/axiosInstance';
import  axios from 'axios';


export const balanceLoading=()=>{
    return {
        type: actionTypes.BALANCE_LOADING
    }
}

export const balanceSuccess=(bal)=>{
    return {
        type: actionTypes.BALANCE_SUCCESS,
        balance:bal
    }
}

export const balanceFail=(err)=>{
    return {
        type: actionTypes.BALANCE_FAIL,
        error:err
    }
}



export const balanceInit=(id,token)=>{
    return dispatch=>{
        //dispatch(balanceLoading());
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        axiosInstance.get(`check-balance/${id}?token=${token}`,{cancelToken:source.token} ).then( resp =>{
           // console.log(token,resp.data.status);
            const {bal} = resp.data;
            //const bal =  data.map(item => +item.amount).reduce( (prev,cur) => prev + cur ,0);  // added + to covert to number
           dispatch(balanceSuccess(bal))
            //console.log(data,bal);
            //this.setState({balance: bal ,loading:false});
        }).catch(error => 
            {
                if (axios.isCancel(error)) {
                    dispatch(balanceFail(error))
                }else{
                    dispatch(balanceFail(error))
                }
            } 
        );
    }
}




export const searchBalanceLoading=()=>{
    return {
        type: actionTypes.SEARCH_BALANCE_LOADING
    }
}

export const searchBalanceSuccess=(bal)=>{
    return {
        type: actionTypes.SEARCH_BALANCE_SUCCESS,
        searchBalance:bal
    }
}

export const searchBalanceFail=(err)=>{
    return {
        type: actionTypes.SEARCH_BALANCE_FAIL,
        error:err
    }
}

export const postSearchForm =(id,info)=>{
    return dispatch =>{
         dispatch(searchBalanceLoading())
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        axiosInstance.post(`check-balance-by-date/${id}`,info,{
          cancelToken: source.token
        }).then(resp =>{//this.setState({searchLoading:false});
         const {dataDeposit,dataWithdrawal} = resp.data;//console.log(data);// when you get back object use object.keys to convert to map
         //console.log(dataDeposit,dataWithdrawal);
         let  Deposit = 0;
         let  Withdrawal = 0;

         if(dataDeposit === null || dataDeposit ==="undefined"){
            Deposit = 0;
         }else{
            Deposit = Object.keys(dataDeposit).map(item =>{// console.log(item, data[item]['amount']);
            return +dataDeposit[item]['amount'];
            }).reduce( (prev,cur) => prev + cur ,0);// console.log(bal);
         }

         if(dataWithdrawal === null || dataWithdrawal ==="undefined"){
            Withdrawal = 0;
            console.log('not defined');
         }else{
            Withdrawal = Object.keys(dataWithdrawal).map(item =>{
                return +dataWithdrawal[item]['amount'];
                }).reduce( (prev,cur) => prev + cur ,0);
         }       


         const bal = Deposit - Withdrawal;

        dispatch(searchBalanceSuccess(bal));

        }).catch(error => 
          {
              if (axios.isCancel(error)) {
                  dispatch(searchBalanceFail(error))
              }else{
                dispatch(searchBalanceFail(error))
              }
          } 
        );
    }
}



export const endSavingsLoading=()=>{
    return {
        type: actionTypes.END_SAVINGS_LOADING
    }
}

export const endSavingsSuccess=()=>{
    return {
        type: actionTypes.END_SAVINGS_SUCCESS,
    }
}

export const endSavingsFail=(err)=>{
    return {
        type: actionTypes.END_SAVINGS_FAIL,
        error:err
    }
}


export const endSavings=(id,token)=>{
    return dispatch=>{

        dispatch(endSavingsLoading())
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        axiosInstance.get(`complete-account/${id}?token=${token}`,{
          cancelToken: source.token
        }).then(resp =>{//this.setState({endLoading:false,confirmCloseAlert:true,balance: 0,searchBalance: 0});
      //  console.log(token,resp.data.status);
          dispatch(endSavingsSuccess())
        }).catch(error => 
          {
              if (axios.isCancel(error)) {
                  dispatch(endSavingsFail(error))
              }else{
                 // console.log(error)
                  dispatch(endSavingsFail(error))
              }
          } 
        );
    }
}