import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js'; //npm i --save bootstrap jquery popper.js
//import $ from 'jquery';
//import Popper from 'popper.js';
import 'font-awesome/css/font-awesome.min.css';
import {BrowserRouter as Router } from "react-router-dom";
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {createStore, applyMiddleware,combineReducers,compose} from 'redux';
import usersReducer from './store/reducers/usersReducer';
import accountReducer from './store/reducers/accountReducer';
import popupReducer from './store/reducers/popupReducer';
import summaryReducer from './store/reducers/summaryReducer';
import balanceReducer from './store/reducers/balanceReducer';
import authReducer    from './store/reducers/authReducer';
import statisticsReducer from './store/reducers/statisticsReducer';
//const store = createStore(usersReducer);


const rootStore = combineReducers({
  usersReducer,
  popupReducer,
  accountReducer,
  summaryReducer,
  balanceReducer,
  authReducer,
  statisticsReducer 
})

 const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
 const store = createStore(rootStore, composeEnhancers( applyMiddleware(thunk)));

ReactDOM.render(
    <Provider  store ={store}>
    <Router>
    <App />
    </Router>
    </Provider>
    ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
