import * as actionTypes from './actionTypes';
import  axiosInstance from '../../Helpers/Axios/axiosInstance';
import  axios from 'axios';



export const statisticsLoading=()=>{
    return {
        type: actionTypes.STATISTICS_LOADING
    }
}

export const statisticsSuccess=(data)=>{
    return {
        type: actionTypes.STATISTICS_SUCCESS,
        success:data
    }
}

export const statisticsFail=(err)=>{
    return {
        type: actionTypes.STATISTICS_FAIL,
        error:err
    }
}


export const statistics=(info,token)=>{


    return dispatch=>{
        dispatch(statisticsLoading());
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        axiosInstance.post(`check-trans-by-date?token=${token}`,info,{
            cancelToken: source.token
            }).then(resp =>{

                console.log(resp.data,'today');
               //const  {deposit, withdrawal, bal} = resp.data;
                dispatch(statisticsSuccess(resp.data));
                 
            }).catch(error => 
            {
                if (axios.isCancel(error)) {
                    dispatch(statisticsFail(error));
                    //   this.setState({loading:false});
                    //   console.log('post Request canceled');
                }else{
                    dispatch(statisticsFail(error));
                    //   console.log(error)
                    //   this.setState({loading:false});
                }
            } 
            );
        }
     
}