
export const USERS_LOADING_START = "USERS_LOADING_START";
export const USERS_SUCCESS = "USERS_SUCCESS";
export const USERS_FAIL = "USERS_FAIL";

export const USER_PROFILE_LOADING ="USER_PROFILE_LOADING";
export const USER_PROFILE_SUCCESS ="USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL    ="USER_PROFILE_FAIL";
export const CANCEL_PROFILE_AND_POPUP_FORM ="CANCEL_PROFILE_AND_POPUP_FORM";


export const CREATE_ACCOUNT_LOADING ="CREATE_ACCOUNT_LOADING";
export const CREATE_ACCOUNT_SUCCESS ="CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAIL ="CREATE_ACCOUNT_FAIL";
export const FALSIFY_REDIRECT = "FALSIFY_REDIRECT";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"

export const SHOW_ADD_FORM = "SHOW_ADD_FORM"


export const EDIT_ACCOUNT_LOADING ="EDIT_ACCOUNT_LOADING";
export const EDIT_ACCOUNT_SUCCESS ="EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_FAIL ="EDIT_ACCOUNT_FAIL";
export const SET_USER_ID_FOR_EDIT_ACCOUNT ="SET_USER_ID_FOR_EDIT_ACCOUNT";


export const CREATE_POPUP_FORM_LOADING ="CREATE_POPUP_FORM_LOADING";
export const CREATE_POPUP_FORM_SUCCESS ="CREATE_POPUP_FORM_SUCCESS";
export const CREATE_POPUP_FORM_ERROR ="CREATE_POPUP_FORM_ERROR";


export const SUMMARY_LOADING ="SUMMARY_LOADING";
export const SUMMARY_SUCCESS ="SUMMARY_SUCCESS";
export const SUMMARY_FAIL ="SUMMARY_FAIL";
export const SUMMARY_ID_AND_NAME_INIT ="SUMMARY_ID_AND_NAME_INIT";
export const SUMMARY_EDIT_TRANSACTION ="SUMMARY_EDIT_TRANSACTION";
export const SUMMARY_TRANS_CANCEL ="SUMMARY_TRANS_CANCEL";
export const SUMMARY_DELETE_TRANS ="SUMMARY_DELETE_TRANS";





export const BALANCE_LOADING ="BALANCE_LOADING";
export const BALANCE_SUCCESS ="BALANCE_SUCCESS";
export const BALANCE_FAIL    ="BALANCE_FAIL";


export const SEARCH_BALANCE_LOADING = "SEARCH_BALANCE_LOADING"
export const SEARCH_BALANCE_SUCCESS = "SEARCH_BALANCE_SUCCESS"
export const SEARCH_BALANCE_FAIL = "SEARCH_BALANCE_FAIL"


export const END_SAVINGS_LOADING = "END_SAVINGS_LOADING"
export const END_SAVINGS_SUCCESS = "END_SAVINGS_SUCCESS"
export const END_SAVINGS_FAIL    = "END_SAVINGS_FAIL"


export const AUTH_LOADING        = "AUTH_LOADING"
export const AUTH_SUCCESS        = "AUTH_SUCCESS"
export const AUTH_FAIL           = "AUTH_FAIL"
export const AUTO_LOGOUT         = "AUTO_LOGOUT"
export const AUTH_FALSIFY_REDIRECT = "AUTH_FALSIFY_REDIRECT";



export const PASS_LOADING        = "PASS_LOADING"
export const PASS_SUCCESS        = "PASS_SUCCESS"
export const PASS_FAIL           = "PASS_FAIL"


export const STATISTICS_LOADING ="STATISTICS_LOADING";
export const STATISTICS_SUCCESS ="STATISTICS_SUCCESS";
export const STATISTICS_FAIL    ="STATISTICS_FAIL";