import React, { Component } from 'react';
import Aux from '../../Hoc/Aux/Aux';
import styles from './NewAccount.module.css';
import formValidation from '../../Helpers/FormValidation/StandardForm';
import Spinner from '../../Partials/Spinner/Spinner';
import Response from '../../Partials/Response/Response';
import * as actions from '../../store/actions/index';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

class NewAccount extends Component {
  state = {
    myForm:{
        name:     '',
        phone:    '',
        address:  '',
    },
  //  id:'',
    formErrors:{
        name: '',
        phone: '',
        address:'',    
        capital:'',
    },
    loading:false,
   // success: null,
  //  error : null,
    display:false, // for hiding alert
    show: false ,

}




componentDidMount(){
    const {user} = this.props.location;
    if(!user){
      this.props.history.replace('/customers');
    }
    this.setState({
        ...this.state,
           //  id:user.id,
             myForm:{
                ...this.state.myForm,
                  name:user.name,
                  phone:user.phone,
                  address:user.address,
             },
             
    })

    this.props.setUserIdForEditAccount(user.id);
    //console.log(user);
    //console.log(this.state.myForm);
    
}

changeHandler = (event) =>{
       
  const {name, value} = event.target;
 // console.log(name + ' '+ value);
  const {myForm,formErrors} = this.state;
  // // validation
  const v = new formValidation('alertError',styles.Errors);
  const {errorState,valueState} = v.validation(name,value,formErrors,myForm);

  // //set state
  let  updateForm = {...myForm};
  updateForm.formErrors = errorState;
  updateForm.myForm = valueState;
  this.setState({myForm:updateForm});
}


submitFormHandler=(event)=>{
  event.preventDefault();
  this.setState({loading:true});
   //console.log(21);
  const {myForm,formErrors} = this.state;
  const v = new formValidation('alertError',styles.Errors);
  if( v.isformValid(formErrors,myForm)){

    const info = {
      name:       myForm.name,
      phone:      myForm.phone,
      address:    myForm.address,
   }


//console.log(info.name+' noqqq');
   this.props.postEditAccount(this.props.id,info);

  }else{
      //error
      console.log("many errors");
  }

}

    render(){


      let redirectToUsers = null;
      if(this.props.redirect){
        redirectToUsers = <Redirect to='/customers' />
      }

      const {myForm,formErrors} = this.state;      
      const fieldAlert = new formValidation('alertError',styles.Errors);

      const theForm = (
        <div className={["card", styles.cardsignin ,"my-5"].join(' ')}>
                   <div className="card-body">
                    <h5 className="card-title text-center">Edit {myForm.name}'s Profile</h5>
                     <form className="form-signin" noValidate>


                     <div className={styles.formlabelgroup}>
                       <input type="text" value={myForm.name } className="form-control" name="name" placeholder="Name" onChange={this.changeHandler}  />
                       <div className={fieldAlert.alert(formErrors.name).css}>{fieldAlert.alert(formErrors.name).value}</div>
                    </div>


                     <div className={styles.formlabelgroup}>
                       <input type="text" value={myForm.phone }  className="form-control" name="phone" placeholder="Phone"  onChange={this.changeHandler}  />
                       <div className={fieldAlert.alert(formErrors.phone).css}>{fieldAlert.alert(formErrors.phone).value}</div>
                    </div>


                    <div className={styles.formlabelgroup}>
                       <input type="text" value={myForm.address}  className="form-control" name="address" placeholder="Address" onChange={this.changeHandler}   />
                       <div className={fieldAlert.alert(formErrors.address).css}>{fieldAlert.alert(formErrors.address).value}</div>
                    </div>                   



     
                    <button className={[styles.btn, "btn-lg", "btn btn-primary", "btn-block", "text-uppercase" ].join(' ')}
                           disabled={!fieldAlert.isformValid(formErrors,myForm)}
                           onClick={this.submitFormHandler}>Edit </button>
               </form>
            </div>
          </div>
      )

        return (
          
          
          
          <Aux>    
                   {this.props.error ? <Response styles={['alert','alert-danger'].join(' ')} >{this.props.error.toString()}</Response>: null }
                   {redirectToUsers}
                   {this.props.loading ? <Spinner/> : theForm } 
                  
  
          </Aux>


        )
    }
}

const mapStateToProps = state =>{
  return{
          id: state.accountReducer.userid,
          redirect: state.accountReducer.redirection,
          loading: state.accountReducer.loading,
          error:state.accountReducer.error
  }
}


const mapDispatchToProps = dispatch =>{
  return {
    postEditAccount : (id,info)=> dispatch(actions.postEditAccount(id,info)),
    setUserIdForEditAccount: (id)=> dispatch(actions.setUserIdForEditAccount(id))
  }
}




export default connect(mapStateToProps,mapDispatchToProps)(NewAccount);