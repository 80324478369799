import * as actionTypes from './actionTypes';
import  axiosInstance from '../../Helpers/Axios/axiosInstance';
import  axios from 'axios';

export const summaryIdAndNameInit=(id,name)=>{
    return {
        type: actionTypes.SUMMARY_ID_AND_NAME_INIT,
        name:name,
        id:id
    }
}


export const summaryLoading=()=>{
    return {
        type: actionTypes.SUMMARY_LOADING
    }
}

export const summarySuccess=(transactions)=>{
    return {
        type: actionTypes.SUMMARY_SUCCESS,
        transactions:transactions
    }
}

export const summaryFail=(err)=>{
    return {
        type: actionTypes.SUMMARY_FAIL,
        error:err
    }
}


export const summaryInit =(id,token)=>{
    return dispatch =>{

        // you do this because of  manual refresh
        if(!token){
            token = localStorage.getItem('token');
        }
        dispatch(summaryLoading());
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        axiosInstance.get(`customers/summary/${id}?token=${token}`,{cancelToken:source.token} ).then( resp =>{
         //   console.log(token,resp.data.status);
        //  const transactions = resp.data.data;
           // this.setState({transactions,loading:false});
           dispatch(summarySuccess(resp.data.data));
        }).catch(error => 
            {
                if (axios.isCancel(error)) {
                    dispatch(summaryFail(error));
                }else{
                    dispatch(summaryFail(error));
                }
            } 
        );
    }
}

export const summaryEditTransaction=(data)=>{
    return {
        type: actionTypes.SUMMARY_EDIT_TRANSACTION,
        transaction:data
    }
}

export const summaryTransCancel=()=>{
    return {
        type: actionTypes.SUMMARY_TRANS_CANCEL
    }
}


export const summaryUpdateTrans=(myid,token)=>{
    return dispatch=> {

        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
    const source = CancelToken.source();
    axiosInstance.get(`customers/summary/${myid}?token=${token}`,{cancelToken:source.token} ).then( resp =>{
       // console.log(token,resp.data.status);
      //const transactions = resp.data.data;
      //  this.setState({transactions,loading:false});
        //this.setState({showEditSavings:false});
        dispatch(summarySuccess(resp.data.data));
        dispatch(summaryTransCancel());
    }).catch(error => 
        {
            if (axios.isCancel(error)) {
                dispatch(summaryFail(error));
            }else{
                dispatch(summaryFail(error));
            }
        } 
    );
    }
}


export const summaryDeleteTrans=(id)=>{
    return {
        type: actionTypes.SUMMARY_DELETE_TRANS,
        id:id
    }
}

export const summaryDeleteOneTrans=(id,amount,token)=>{
    return dispatch=>{
        window.confirm(`Delete ${amount} ?`);
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
         axiosInstance.get(`/delete-savings/${id}?token=${token}`,{cancelToken:source.token} ).then( resp =>{
         //   console.log(token,resp.data.status);
        //    this.setState( prestate =>({
        //           // loading:false,
        //            transactions: prestate.transactions.filter(transaction => transaction.id !== id),
        //        }));
        dispatch(summaryDeleteTrans(id))
       }).catch(error => 
           {
               if (axios.isCancel(error)) {
                dispatch(summaryFail(error));
               }else{
                dispatch(summaryFail(error));
               }
           } 
       );
    }
}