import React, { Component } from 'react';
import Aux from '../../Hoc/Aux/Aux';
import styles from '../Login/Login.module.css';
import formValidation from '../../Helpers/FormValidation/StandardForm';
import Spinner from '../../Partials/Spinner/Spinner';
import * as actions from '../../store/actions/index';
import {connect}  from 'react-redux';
import Response from '../../Partials/Response/Response';


class ChangePassword extends Component {


    state ={
        myForm:{
          password:null,
        },
        formErrors:{
          password: '',
        },
      }

      changeHandler=(event)=>{
        const {name, value} = event.target;
        const {myForm,formErrors} = this.state;
        const v = new formValidation();
        const {errorState,valueState} = v.validation(name,value,formErrors,myForm);
        console.log(errorState,valueState);
    
        let  updateForm = {...this.state};
        updateForm.formErrors = errorState;
        updateForm.myForm = valueState;
        this.setState({myForm:updateForm.myForm,formErrors:updateForm.formErrors});
      }


      submitFormHandler=(event)=>{
        event.preventDefault();
        const {myForm,formErrors} = this.state;
        const v = new formValidation('alertError',styles.Errors);
        if( v.isformValid(formErrors,myForm)){
            const info = {
                password:myForm.password
            }
            console.log(this.propsuserid,info,this.props.token);
            this.props.passwordReset(this.props.userId,info,this.props.token)
        }
    }  
      

      render(){

        const {myForm,formErrors} = this.state;
        const fieldAlert = new formValidation('alertError',styles.Errors);

        let Form = ( 
            <div className={["card", styles.cardsignin ,"my-5"].join(' ')}>
              <div className="card-body">
                <form className="form-signin">
                
                  <div className={styles.formlabelgroup}>
                    <input type="password" name="password" className="form-control" 
                    onChange={ (event)=>this.changeHandler(event)} placeholder="Password" required/>
                    <div className={fieldAlert.alert(formErrors.password).css}>{fieldAlert.alert(formErrors.password).value}</div>
                  </div>
                  <button 
                                  disabled={!fieldAlert.isformValid(formErrors,myForm)}
                                  onClick={this.submitFormHandler}
                  className={[styles.btn, "btn-lg", "btn btn-primary", "btn-block", "text-uppercase" ].join(' ')}type="submit">Change Password</button>
                </form>
              </div>
              </div>
             );

            if(this.props.loading){
            Form = (<Spinner></Spinner>);
            }                 











        return (<Aux>
                {this.props.error? <Response styles={['alert','alert-danger'].join(' ')}>{this.props.error.toString()}</Response>:null}
                {this.props.success? <Response styles={['alert','alert-success'].join(' ')}>{this.props.success}</Response>:null}
                {Form}
               </Aux>

        )
      }

}


const mapStateToProps = state =>{
    return {
        userId : state.authReducer.userId,
        token  : state.authReducer.token,
        loading: state.authReducer.loading,
        success: state.authReducer.success
    }

}

const mapDispatchToProps = dispatch =>{
    return {
        passwordReset : (id,info,token)=> dispatch(actions.changePassword(id,info,token))
    }

}

export default connect(mapStateToProps,mapDispatchToProps)(ChangePassword);