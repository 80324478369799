import React from 'react';
import styles from './balanceform.module.css'
import DatePicker from "react-datepicker";
import Aux from '../../Hoc/Aux/Aux';
import Response from '../../Partials/Response/Response';


const balanceform = (props) => {

      let mybalance = "loading ...";
      if(!props.loading){

        const num =  new Intl.NumberFormat().format(props.balance) 
        mybalance = (
          <div className="mt-3">
            <div className="alert alert-secondary">
            <strong>Main Balance:</strong> {"₦ "+num}
            </div>
            </div>
        );
      }



     
      let searchbalance = "loading...";
      if(!props.searchLoading){
        const num =  new Intl.NumberFormat().format(props.searchBalance) 
          searchbalance = (
             <div className="mt-3">
               <div className="alert alert-primary">
               <strong> Balance:</strong> {"₦ "+num}
               </div>
               </div>
           );

      }

      


    return (  
    
              <Aux>



                      {props.error? <Response styles={['alert','alert-danger'].join(' ')}>{props.error.toString()}</Response>: null}


                    <form className="form-signin" noValidate>
                    <div className={styles.formlabelgroup}>
                    <div><strong>FROM:</strong></div>
                    <DatePicker
                      selected={props.myForm.dateOne}
                      className="form-control mr-3" 
                      name="dateOne"
                      dateFormat="yyyy-MM-dd"
                      onChange={date => props.changeDateOne(date)}
                    />

                    <div><strong>TO:</strong></div>
                    <DatePicker
                      selected={props.myForm.dateTwo}
                      className="form-control mr-3" 
                      name="dateTwo"
                      dateFormat="yyyy-MM-dd"
                      onChange={date2 => props.changeDateTwo(date2)}
                    />
                    </div>   

                    <button type="submit" className={[styles.btn, "btn btn-primary ", "text-uppercase" ].join(' ')} 
                    onClick={props.submit}>Balance</button>
                    <button type="submit" className={[styles.btn, "btn btn-danger ml-3", "text-uppercase" ].join(' ')} 
                    onClick={()=>props.summary(props.id,props.name)}> Summary </button>
                    </form> 

                     
                      {searchbalance}
                      {mybalance}
                     
              </Aux>

  
  );
}
 
export default balanceform;