import * as actionTypes from './actionTypes';
import  axiosInstance from '../../Helpers/Axios/axiosInstance';
import  axios from 'axios';
import * as actions from './index';

export const createPopupFormLoading =()=>{
    return {
       type: actionTypes.CREATE_POPUP_FORM_LOADING
    }
}

export const createPopupFormError =(err)=>{
    return {
       type: actionTypes.CREATE_POPUP_FORM_ERROR,
       error: err
    }
}


export const falsifyRedirectPopup = ()=>{
    return {
        type : actionTypes.FALSIFY_REDIRECT,
    }
}

export const createPopupFormSuccess =()=>{
    return {
       type: actionTypes.CREATE_POPUP_FORM_SUCCESS
    }
}

export const createPopup=(info,token)=>{
    return dispatch =>{
        
        dispatch(createPopupFormLoading());
        const CancelToken = axios.CancelToken;  // remember always use plain axios for this one
        const source = CancelToken.source();
        axiosInstance.post('customer/save',info,{
        cancelToken: source.token
        }).then(resp =>{
            dispatch(createPopupFormSuccess());
             // update summary list
             //console.log(info.user_id,token);
             dispatch(actions.summaryUpdateTrans(info.user_id,token)); // update summary page
             dispatch(actions.cancelProfileAndPopupForm()); // cancel popup
        }).catch(error => 
        {
            if (axios.isCancel(error)) {
                dispatch(createPopupFormError(error));
                //   this.setState({loading:false});
                //   console.log('post Request canceled');
            }else{
                dispatch(createPopupFormError(error));
                //   console.log(error)
                //   this.setState({loading:false});
            }
        } 
        );
            }

}
