import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loading:true,
    error:null,
    balance:null,
    searchBalance:null,
    searchLoading:false,
    endLoading:false,
    confirmCloseAlert:false

}

const balanceReducer =(state = initialState,action)=>{
    switch(action.type){
        case actionTypes.BALANCE_LOADING:
        return{
            ...state,
            loading:true,
            error:null,
            confirmCloseAlert:false
        }
        case actionTypes.BALANCE_SUCCESS:
        return{
            ...state,
            loading:false,
            balance:action.balance
        }
        case actionTypes.BALANCE_FAIL:
        return{
            ...state,
            loading:false,
            error:action.error
        }
        case actionTypes.SEARCH_BALANCE_LOADING:
        return{
            ...state,
            searchBalance:true,
            searchLoading:true,

        }
        case actionTypes.SEARCH_BALANCE_SUCCESS:
        return{
            ...state,
            searchLoading:false,
            searchBalance:action.searchBalance
        }
        case actionTypes.SEARCH_BALANCE_FAIL:
        return {
            ...state,
            error:action.error
        }
        case actionTypes.END_SAVINGS_LOADING:
        return{
            ...state,
            endLoading:true
        }
        case actionTypes.END_SAVINGS_SUCCESS:
        return{
            ...state,
            endLoading:false,
            confirmCloseAlert:true,
            balance: 0,
            searchBalance: 0
        }
        default:
        return state

    }
}


export default balanceReducer;